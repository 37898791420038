<template>
  <div>
    <FacilityFilter
      v-if="$can('filter', 'facilities')"
      :loading="loading"
      @searchFilter="searchFilter"
      @filterPage="filterPage"
    />
    <MapFacility
      v-if="conditionalFacility"
      :facilityItem="facilityItem"
      @cerrarModalFacility="closeModalFacility"
    />
    <b-card>
      <TitleTable titleTable="Facilities" iconTable="HomeIcon" />
      <div class="m-1">
        <FacilityEdit
          v-if="isEditFacilitySidebarActive"
          :is-edit-facility-sidebar-active.sync="isEditFacilitySidebarActive"
          :itemEdit="itemEdit"
          @editFacilitySuccess="editFacilitySuccess"
        />
        <FacilityAdd
          v-if="isAddNewFacilitySidebarActive"
          :is-add-new-facility-sidebar-active.sync="
            isAddNewFacilitySidebarActive
          "
          @createFacility="createFacility"
        />
        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <treeselect
              v-model="perPage"
              :options="perPageOptions"
              :clearable="true"
              class="w-25"
            />
          </b-col>
          <!-- {{ $t("Search") }} -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <GoBack class="mr-2" />
              <b-button
                v-if="$can('store', 'facilities')"
                variant="primary"
                @click="isAddNewFacilitySidebarActive = true"
                :disabled="loading"
              >
                <span v-if="!loading" class="text-nowrap"
                  >{{ $t("Add") }} Facility</span
                >
                <span v-else>
                  <SpinnerLoading />
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        :items="items"
        :fields="fields"
        hover
        bordered
        responsive
        primary-key="id"
        class="position-relative"
        show-empty
        empty-text="No matching records found"
      >
        <template #cell(name)="data">
          <b-link
            :to="{
              name: 'admin-facilities-view',
              params: { id: data.item.id },
            }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.name }}
          </b-link>
        </template>
        <template #cell(status)="data">
          <div class="text-nowrap">
            <b-badge
              :variant="data.value ? 'success' : 'danger'"
              class="badge-glow cursor-pointer"
              @click="changeStatus(data.item)"
            >
              <span class="align-text-top text-capitalize">{{
                data.item.status ? $t("Active") : $t("Inactive")
              }}</span>
            </b-badge>
          </div>
        </template>
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret>
            <template #button-content>
              <i class="fas fa-ellipsis-v"></i>
            </template>

            <b-dropdown-item
              v-if="$can('update', 'facilities')"
              :to="{
                name: 'admin-facilities-edit',
                params: { id: data.item.id },
              }"
            >
              <i class="fas fa-pen"></i>
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="deleteFacility(data.item)">
              <i class="fas fa-trash-alt"></i>
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>

            <b-dropdown-item @click="viewMapFacility(data.item)">
              <i class="fas fa-map-marked"></i>
              <span class="align-middle ml-50">View </span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <PaginationTable
        :dataMeta="dataMeta"
        :totalUsers="totalUsers"
        :perPage="perPage"
        :currentPage="currentPage"
        @page-changed="pageChanged"
      />
    </b-card>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BBadge,
  BLink,
  BAvatar,
  BButton,
  BDropdown,
  BDropdownItem,
  VBTooltip,
} from "bootstrap-vue";

import axiosF from "@/core/services/apiInsurance/admin/facilities";

import ToastNotification from "@/components/ToastNotification";
import SpinnerLoading from "@/components/SpinnerLoading";
import PaginationTable from "@/components/PaginationTable";
import TitleTable from "@/components/TitleTable.vue";
import GoBack from "@/components/ButtonBack.vue";
import Swal from "sweetalert2";

import FacilityEdit from "./FacilityEdit.vue";
import FacilityAdd from "./FacilityAdd.vue";
import FacilityFilter from "./FacilityFilter.vue";
import MapFacility from "./MapFacility.vue";

import { ref } from "@vue/composition-api";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BBadge,
    BAvatar,
    BButton,
    BDropdown,
    BDropdownItem,
    BLink,
    FacilityEdit,
    FacilityAdd,
    FacilityFilter,
    MapFacility,
    ToastNotification,
    SpinnerLoading,
    PaginationTable,
    TitleTable,
    GoBack,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      fields: [
        // A virtual column that doesn't exist in items
        { key: "id", thClass: "px-3" },
        // A column that needs custom formatting
        { key: "name", label: "Name", sortable: true },
        { key: "phone", label: "Phone" },
        // A regular column
        // { key: "Popularity", label: "Popularity" },
        // A regular column
        { key: "email", label: "Email", sortable: true },
        { key: "number_of_rooms", label: "Number Rooms", sortable: true },
        // A virtual column made up from two fields
        { key: "cities.name", label: "City" },
        { key: "status", label: "Status" },
        { key: "actions", label: "Actions" },
      ],
      items: null,
      loading: false,
      isEditFacilitySidebarActive: false,
      isAddNewFacilitySidebarActive: false,
      itemEdit: null,
      dataMeta: { from: 1, to: 1, of: 1 },
      totalUsers: 0,
      currentPage: {
        page: 1,
      },
      perPage: 10,
      perPageOptions: [10, 25, 50, 100].map((i) => ({
        id: i,
        label: `Show ${i} Entries`,
      })),
      filtro: false,
      facilityFilter: null,
      facilityItem: null,
      conditionalFacility: false,
    };
  },
  mounted() {
    this.getFacility();
  },
  watch: {
    perPage(newVal) {
      if (!this.filtro) {
        this.getFacility();
      } else {
        this.searchFilter(this.facilityFilter);
      }
    },
  },
  methods: {
    getFacility() {
      axiosF
        .facilityList(this.perPage)
        .then(({ data, total, current_page, per_page, ...res }) => {
          this.items = data;
          this.totalUsers = total;
          this.dataMeta.of = total;
          this.dataMeta.from = res.from;
          this.dataMeta.to = res.to;
          this.currentPage.page = current_page;
        });
    },
    editFacility(item) {
      this.isEditFacilitySidebarActive = true;
      this.itemEdit = ref(JSON.parse(JSON.stringify(item)));
    },
    editFacilitySuccess(value) {
      if (value) {
        this.isEditFacilitySidebarActive = false;
        this.$refs.toast.success("Type Facility updated successfully");
      } else {
        this.$refs.toast.error("Error updating type facilitie");
      }
      this.pageChanged(this.currentPage.page);
    },
    createFacility(status) {
      if (status) {
        this.$refs.toast.success("Facility type added successfully");
      } else {
        this.$refs.toast.danger("Facility type not added");
      }
      this.isAddNewFacilitySidebarActive = false;
      this.pageChanged(this.currentPage.page);
    },
    deleteFacility(item) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axiosF.facilityDelete(item.id).then((response) => {
            this.$refs.toast.success(
              "Facility type has been deleted successfully"
            );
            this.pageChanged(this.currentPage.page);
          });
        }
      });
    },
    pageChanged(page) {
      if (!this.filtro) {
        axiosF
          .facilityPagination(this.perPage, page)
          .then(({ data, total, current_page, ...res }) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          });
      } else {
        axiosF
          .facilityFilterPagination(this.perPage, page, this.facilityFilter)
          .then(({ data, total, current_page, ...res }) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          });
      }
    },
    searchFilter(value) {
      if (this.filtro) {
        this.loading = true;
        this.facilityFilter = value;
        axiosF
          .facilityFilter(this.perPage, value)
          .then(({ data, total, current_page, ...res }) => {
            this.loading = false;
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.getFacility();
      }
    },
    filterPage(value) {
      this.filtro = value;
    },
    changeStatus(item) {
      axiosF
        .facilityUpdate(item.id, { ...item, status: !item.status })
        .then((res) => {
          this.$refs.toast.success(
            "Facility status has been changed successfully"
          );
        })
        .catch((err) => {
          this.$refs.toast.error("Facility status has not been changed");
        });
      this.pageChanged(this.currentPage.page);
    },
    viewMapFacility(item) {
      this.facilityItem = item.adress;
      this.conditionalFacility = true;
    },
    closeModalFacility() {
      this.conditionalFacility = false;
    },
  },
};
</script>

<style></style>
