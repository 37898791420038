<template>
  <b-sidebar
    id="add-new-state-sidebar"
    :visible="isAddNewFacilitySidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-facility-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">{{ $t("Add") }} {{ $t("New") }} Facility</h5>

        <i class="fas fa-times ml-1 cursor-pointer" @click="hide"></i>
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="name"
            rules="required"
          >
            <b-form-group :label="$t('Form.Name')" label-for="name">
              <b-form-input
                id="name"
                v-model.trim="stateData.name"
                autofocus
                :state="getValidationState(validationContext)"
                placeholder="Texas"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="email"
            rules="required"
          >
            <b-form-group label="Email" label-for="email">
              <b-form-input
                id="email"
                v-model.trim="stateData.email"
                autofocus
                :state="getValidationState(validationContext)"
                placeholder="facility@facility.com"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Phone Number -->
          <validation-provider
            #default="validationContext"
            name="phone"
            rules="required"
          >
            <b-form-group :label="$t('Form.Phone')" label-for="phone">
              <b-form-input
                v-model="formatPhone"
                class="form-control"
                :state="getValidationState(validationContext)"
                placeholder="1234 567 8900"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- pbx -->
          <validation-provider
            #default="validationContext"
            name="pbx"
            rules="required"
          >
            <b-form-group label="Pbx" label-for="pbx">
              <b-form-input
                id="pbx"
                v-model.number="stateData.pbx"
                autofocus
                :state="getValidationState(validationContext)"
                placeholder="750002"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Address -->
          <validation-provider
            #default="validationContext"
            name="address"
            rules="required"
          >
            <b-form-group label="Address" label-for="address">
              <b-form-input
                id="address"
                v-model.number="stateData.adress"
                autofocus
                :state="getValidationState(validationContext)"
                placeholder="Texas 475 N. Main St."
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- city -->
          <validation-provider
            #default="validationContext"
            name="city"
            rules="required"
          >
            <b-form-group :label="$t('Form.City')" label-for="city">
              <treeselect
                id="city"
                v-model="stateData.cities_id"
                :load-options="searchCities"
                :async="true"
                :defaultOptions="optionsCities"
                :normalizer="normalizer"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Zip -->
          <validation-provider
            #default="validationContext"
            name="zip"
            rules="required"
          >
            <b-form-group label="Zip-Code" label-for="zip-code">
              <b-form-input
                id="zip-code"
                v-model.number="stateData.zip_code"
                autofocus
                :state="getValidationState(validationContext)"
                placeholder="750002"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- attention_schedule -->
          <validation-provider
            #default="validationContext"
            name="attention_schedule"
            rules="required"
          >
            <b-form-group
              label="Attention Schedule Monday-Saturday"
              label-for="attention_schedule"
            >
              <div class="d-flex align-items-center">
                <flat-pickr
                  v-model="stateData.attention_schedule.mon_to_sat.from"
                  :config="configAttentionSchedule"
                  class="form-control"
                  :state="getValidationState(validationContext)"
                  placeholder="Monday-Saturday"
                />
                <span class="p-1">to</span>
                <flat-pickr
                  v-model="stateData.attention_schedule.mon_to_sat.to"
                  :config="configAttentionSchedule"
                  class="form-control"
                  :state="getValidationState(validationContext)"
                  placeholder="Monday-Saturday"
                />
              </div>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="attention_schedule"
            rules="required"
          >
            <b-form-group
              label="Attention Schedule Sunday"
              label-for="attention_schedule"
            >
              <div class="d-flex align-items-center">
                <flat-pickr
                  v-model="stateData.attention_schedule.sunday.from"
                  :config="configAttentionSchedule"
                  class="form-control"
                  :state="getValidationState(validationContext)"
                  placeholder="Sunday"
                />
                <span class="p-1">to</span>
                <flat-pickr
                  v-model="stateData.attention_schedule.sunday.to"
                  :config="configAttentionSchedule"
                  class="form-control"
                  :state="getValidationState(validationContext)"
                  placeholder="Sunday"
                />
              </div>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- date creation -->
          <validation-provider
            #default="validationContext"
            name="date_creation"
            rules="required"
          >
            <b-form-group label="Date of creation" label-for="date_creation">
              <flat-pickr
                v-model="stateData.creation_date"
                :config="config"
                class="form-control"
                :state="getValidationState(validationContext)"
                placeholder="MM-DD-YYYY"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <!-- Insurance -->
          <validation-provider
            #default="validationContext"
            name="Insurance"
            rules="required"
          >
            <b-form-group label="Insurance" label-for="insurance">
              <treeselect
                id="insurance"
                v-model="stateData.insurance"
                :state="getValidationState(validationContext)"
                :options="optionsStatus"
                :normalizer="normalizer"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- status -->
          <validation-provider
            #default="validationContext"
            name="Status"
            rules="required"
          >
            <b-form-group :label="$t('Form.Status')" label-for="status">
              <treeselect
                id="status"
                v-model="stateData.status"
                :state="getValidationState(validationContext)"
                :options="optionsStatus"
                :normalizer="normalizer"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t("Add") }}
            </b-button>
            <b-button
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("Cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupPrepend,
  BButton,
} from "bootstrap-vue";
import formValidation from "@/core/utils/forms/form-validation";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import flatPickr from "vue-flatpickr-component";
/* import Cleave from "vue-cleave-component";
import "cleave.js/dist/addons/cleave-phone.us"; */

import { required } from "@validations";
import { computed, onMounted, ref } from "@vue/composition-api";

import axiosF from "@/core/services/apiInsurance/admin/facilities";
import axiosC from "@/core/services/apiInsurance/admin/cities";

export default {
  components: {
    flatPickr,

    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    BButton,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  model: {
    prop: "isAddNewFaciltySidebarActive",
    event: "update:is-add-new-facility-sidebar-active",
  },
  props: {
    isAddNewFacilitySidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
     searchCities({ action, searchQuery, callback }) {
      if (action === "ASYNC_SEARCH") {
        clearTimeout(this.setTimeoutBuscador);
        const datos = {
          name: searchQuery,
        };
        this.setTimeoutBuscador = setTimeout(() => {
          axiosC.citiesFilterFilters(datos).then(({ data }) => {
            callback(null, data);
          });
        }, 350);
      }
    },
  },
  data() {
    return {
      required,
      setTimeoutBuscador: "",
      optionsCities: [],
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
        };
      },
    };
  },
  setup(props, { emit }) {
    const optionsStatus = [
      { name: "Active", id: true },
      { name: "Inactive", id: false },
    ];
    const stateDataTem = {
      status: true,
      name: "",
      creation_date: "",
      adress: "",
      phone: "",
      pbx: "",
      email: "",
      attention_schedule: {
        mon_to_sat: {
          from: "",
          to: "",
        },
        sunday: {
          from: "",
          to: "",
        },
      },
      cities_id: "",
      zip_code: "",
      insurance: false,
    };
    const stateData = ref(JSON.parse(JSON.stringify(stateDataTem)));

    const resetuserData = () => {
      stateData.value = JSON.parse(JSON.stringify(stateDataTem));
    };
    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetuserData
    );

    const formatPhone = computed({
      get() {
        let { phone } = stateData.value;
        phone = phone.toString().replace(/\D/g, "");
        const match = phone.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);
        if (match) {
          phone = `(${match[1]}${match[2] ? "" : ""}) ${match[2]}${
            match[3] ? "-" : ""
          }${match[3]}`;
        }
        return phone;
      },
      set(val) {
        stateData.value.phone = val;
      },
    });

    const config = {
      altInput: true,
      altFormat: "m-d-Y",
      dateFormat: "m-d-Y",
      allowInput: true,
      static: true,
    };

    const configAttentionSchedule = {
      enableTime: true,
      noCalendar: true,
      enableSeconds: true,
      dateFormat: "H:i:S",
    };

    const onSubmit = () => {
      const datos = {
        ...stateData.value,
        attention_schedule: {
          mon_to_sat: `${stateData.value.attention_schedule.mon_to_sat.from}-${stateData.value.attention_schedule.mon_to_sat.to}`,
          sunday: `${stateData.value.attention_schedule.sunday.from}-${stateData.value.attention_schedule.sunday.to}`,
        },
      };
      axiosF
        .facilityCreate(datos)
        .then(() => {
          resetuserData();
          emit("createFacility", true);
        })
        .catch(() => {
          emit("createFacility", false);
        });
    };
    return {
      stateData,
      optionsStatus,
      formatPhone,
      config,
      configAttentionSchedule,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>
